import { navigate } from "gatsby"
import React, { useEffect } from "react"
import OakdalePage from "../oakdale"

interface Props {
  location: { state: { formCompleted: boolean } }
}

const OakdaleThanks = (props: Props) => {
  useEffect(() => {
    if (!props.location.state?.formCompleted) {
      navigate("/oakdale")
    }
  }, [props])

  return <OakdalePage didSubmit />
}

/** export */
export default OakdaleThanks
